import React from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createMuiTheme } from '@material-ui/core/styles'

import { HeaderPortal } from './content/header/Header';
import { Footer } from './content/footer/Footer';
import { ArticleCardList } from './content/ArticleCardList';
import bgImage from './content/header/headerBackground.jpeg';
import { ReactComponent as MinstemmeLogoWhite } from './content/header/minstemme-logo_kvit.svg';
// import { ReactComponent as MinstemmeLogoSvart } from './content/header/minstemme-logo_svart.svg';

//#006C85 --primary color
//#E9EFF2 --secondary color
const useStyles = makeStyles((theme) => ({
    /*root: {
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1440,
            /!*background: '#E9EFF2',*!/
            /!* position: 'relative'*!/
        },
    },*/
    content: {
        position: 'relative',
        background: '#E9EFF2',
        maxWidth: 1920,
       /* maxWidth: 1440,*/
        minHeight: '100vh',
        margin: '0 auto',
    },
}));

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#006C85',
        },
        secondary: {
            main: '#E9EFF2',
        },
        action: {
            main: '#0083A8'
        }
    },
    typography: {
        fontFamily: [
            'Roboto',
            'sans-serif',
        ].join(','),
    },
});


export const MinstemmmePortal = () => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.content}>
                <header>
                    <HeaderPortal
                        backgroundImage={`linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${bgImage})`}
                        height='268px'
                        langIcon={{ fill: 'white' }}
                        infoIcon={{ fill: 'white' }}
                        color='white'
                    >
                        <MinstemmeLogoWhite />
                    </HeaderPortal>
                </header>
                <main>
                    <ArticleCardList />
                </main>
                <footer>
                    <Footer />
                </footer>
            </div>
        </ThemeProvider>
    );
};
