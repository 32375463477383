import React, { useEffect } from 'react';
// import makeStyles from '@material-ui/core/styles/makeStyles';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Main } from './content/Main';
import { requestArticle } from './redux/articleAction';


/* const useStyles = makeStyles((theme) => ({
    content: {
        position: "relative",
        background: "white",
        maxWidth: 1920,
        minHeight: "100vh",
        margin: '0 auto',
    }
})); */

export const ArticleView = () => {
    const dispatch = useDispatch(),
        { pageId } = useParams(),
        { data } = useSelector(state => state.article);

    useEffect(() => {
        dispatch(requestArticle(pageId));
    }, [dispatch, pageId])

    return (
        <>
            {data && <Main data={data} anchorObj={false} />}
        </>
    );
};
