import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// import {AppRoutes} from './AppRoutes';
// import {Layout} from './Layout';
import { MinstemmmePortal } from './Home';
import { ArticleView } from './ArticleView';
import ScrollToTop from './scrollToTop';

export const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <Route exact path='/' component={MinstemmmePortal} />
            <Route exact path='/:pageId/:slug' component={ArticleView} />
        </Router>
    );
};
