import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';

import { setParams } from 'common/search/searchParams';

import { requestArticles } from '../redux/articleAction';
import { requestFilter } from 'admin/src/redux/actions/filter';

import { ArticleCard } from './ArticleCard';
import { ArticleFilter } from './ArticleFilter';
import { RadioButtonFilter } from './RadioButtonFilter';


const useStyles = makeStyles((theme) => ({
    gridItem: {
        maxWidth: 343,
        transition: "transform 0.15s ease-in-out",
        "&:hover": {transform: "scale3d(1.05, 1.05, 1)"},
    },
    cardGrid: {
        '& .MuiGrid-spacing-xs-3': {
            justifyContent: 'center',
        },
        paddingBottom: 24
    },
    cardList: {
        marginLeft: 80,
        marginRight: 80,
        marginTop: 16
    },
    showMore: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 78,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 50,
        }
    },
    showMoreButton: {
        width: 132,
        height: 36,
        borderRadius: 4,
        marginTop: 78,
        [theme.breakpoints.down('xs')]: {
            marginTop: 40,
        }
    }
}));


export const ArticleCardList = () => {
    const history = useHistory(),
        dispatch = useDispatch(),
        classes = useStyles(),
        { articles, countFound, fetching } = useSelector(state => state.articleList),
        { level_data } = useSelector(state => state.level),
        { subject_data } = useSelector(state => state.subject),
        { category_data } = useSelector(state => state.category),
        params = new URLSearchParams(history.location.search),
        filters = params.get('filter'),
        q = params.get('q') || '',
        [visible, setVisible] = useState(48),
        [form, setForm] = useState();

    useEffect(() => {
        dispatch(requestFilter('level'));
        dispatch(requestFilter('subject'));
        dispatch(requestFilter('category'));
    }, [dispatch]);

    useEffect(() => {
        if ((level_data.length === 0 || subject_data.length === 0 || category_data.length === 0) || !!form) {
            return;
        }

        const fltrs = (filters && filters.split(',').map(x => parseInt(x))) || [],
            c = category_data.filter(x => fltrs.includes(x.id)) || [],
            l = level_data.filter(x => fltrs.includes(x.id)) || [],
            s = subject_data.filter(x => fltrs.includes(x.id)) || [],
            f = c.concat(l, s);

        setForm({
            filters: f,
            checkedArticle: false,
            checkedAssignment: false,
            checkedContribution: false,
            // radioButtonValue: 'article',
            searchTerm: q,
            // offset: 0,
            // limit: 20
        });
    }, [level_data, subject_data, category_data, form, filters, q]);

    useEffect(() => {
        dispatch(requestArticles(history.location.search));
    }, [dispatch, history.location.search]);

    const loadMore = () => {
        setVisible((prevState) => prevState + 48)
        /* setPrevFetchedRows([...prevFetchedRows, ...filteredRows]);*/
        /* setForm({...form, offset: form.offset + 48})*/
    };

    const onSelect = url => {
        history.push(url);
    };

    const handleFormChange = update => {
        setForm(update);
        history.push({ search: setParams(update) });
    };

    const renderArticleCard = (item, index) => {
        return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={`${index}`} className={classes.gridItem}>
                <ArticleCard article={item} onSelect={onSelect} key={`${index}`} />
            </Grid>
        )
    };

    return form
        ? (
            <>
                <ArticleFilter onChange={handleFormChange} form={form} />
                <Container className={classes.cardGrid} maxWidth="lg">
                    {<RadioButtonFilter onChangeForm={handleFormChange} form={form} fetching={fetching} total={countFound} />}
                    <Grid container spacing={3}>
                        {articles.slice(0, visible).map(renderArticleCard)}
                    </Grid>
                </Container>
                {(articles && visible < countFound) && (
                    <div className={classes.showMore}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.showMoreButton}
                            startIcon={<AddIcon />}
                            onClick={loadMore}
                        >Vis flere</Button>
                    </div>
                )}
            </>
        )
        : null;
};
