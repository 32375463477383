import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import './i18n';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
//import {PersistGate} from 'redux-persist/integration/react';
//import {persistor} from './store'; // store in our case is return form configureStore func call
//<PersistGate persistor={persistor}>
// <App />
// </PersistGate>

//const {store, persistor} = configureStore();

ReactDOM.render(
    <Provider store={configureStore()}>
        <App/>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
