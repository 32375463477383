import {combineReducers} from 'redux';
// import {headerReducer} from '@ekultur/header-state';
import articleList from 'admin/src/redux/reducers/articleList';
// import userAccess from './redux/reducers/userAccess';
import article from 'admin/src/redux/reducers/article';
// import {resources} from './redux/reducers/media';
// import {dmSearch} from './redux/reducers/media';
// import layout from './redux/reducers/layout';
// import app from './redux/reducers/app';
// import mashups from './redux/reducers/mashup';
import level from 'admin/src/redux/reducers/level'
import subject from 'admin/src/redux/reducers/subject';
import category from 'admin/src/redux/reducers/category';
// import {imageData} from './redux/reducers/imageData';
//import {persistReducer} from 'redux-persist';
//import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

/*const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['articleList', 'article', 'level', 'subject', 'category']
}*/

export default combineReducers({
    // other reducers
    // header: headerReducer,
    // app,
    // userAccess,
    articleList,
    article,
    // resources,
    // dmSearch,
    // layout,
    // mashups,
    level,
    subject,
    category,
    // imageData
});

/*
const rootReducer = combineReducers({
    // other reducers
    header: headerReducer,
    app,
    userAccess,
    articleList,
    article,
    resources,
    dmSearch,
    layout,
    mashups,
    level,
    subject,
    category
});

export default persistReducer(persistConfig, rootReducer);
*/
