import config from 'common/config';

export const requestArticles = params => dispatch => {
    dispatch({
        type: 'ARTICLES_REQUESTED'
    });

    fetch(`${config.get('publicApi')}minstemme/articles/${params}`)
        .then(resp => {
            return resp.json();
        })
        .then(data => dispatch({
            type: 'ARTICLES_SUCCEEDED',
            data: data
        })
        )
        .catch(error => dispatch({
            type: 'ARTICLES_FAILED',
            data: error
        }));
};

export const requestArticle = id => dispatch => {
    dispatch({
        type: 'ARTICLE_REQUESTED'
    });

    const headers = new Headers({
        // 'Content-Type': 'application/json',
        // 'accept-language': 'en-US,en;q=0.9,nb;q=0.8,sv;q=0.7,und;q=0.6,no;q=0.5',
        // 'cache-control': 'no-cache'
        'Content-Type':'text/plain; charset=UTF-8'
    });

    fetch(`${config.get('publicApi')}minstemme/article/${id}/`, { headers })
        .then(resp => {
            return resp.json();
        })
        .then(json => {
            dispatch({
                type: 'ARTICLE_SUCCEEDED',
                data: json
            })
        })
        .catch(error => dispatch({
            type: 'ARTICLE_FAILED',
            data: error,
            pageId: id
        }));
};
