import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { CardMedia, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
    leadText: {
        display: 'block',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
        maxHeight: '5.4em',
        lineHeight: '1.8em'
    },
    card: {
        maxWidth: 343,
        height: 433,
        boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.04), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.1)',
        borderRadius: 16,
        cursor: 'pointer'
    },
    cardBtn: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'

    },
    cardMedia: {
        height: 221
    },
    cardContent: {
        flexGrow: 1,
    },
});

export const ArticleCard = ({ article, onSelect }) => {
    let headerImg = article.image_url;
    const classes = useStyles();

    if (headerImg && headerImg.indexOf('png') > -1) {
        headerImg += '&mediaType=image/png';
    }

    const handleSelect = () => {
        onSelect && onSelect(`/${article.dimu_code}/${article.slug}`);
    };

    return (
        <Card className={classes.card} onClick={() => handleSelect()}>
            <CardActionArea className={classes.cardBtn}>
                <CardMedia
                    component="img"
                    className={classes.cardMedia}
                    alt="Article Image"
                    image={headerImg ? headerImg : 'https://pdms.dimu.org/image/02349w5hFUHV?mediaType=image/svg'}
                    title="Article Image"
                />
                <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h5">
                        {article.title}
                    </Typography>
                    <Typography component="p" className={classes.leadText}>
                        <span><b>{{ article: 'Artikkel', assignment: 'Oppgaver', contribution: 'Bidrag' }[article.subtype]}</b> {article.leadText}</span>
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};
